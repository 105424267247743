import { Fragment, useCallback, useEffect, useMemo, useState } from 'react';

// material-ui
import { alpha, useTheme } from '@mui/material/styles';
import { Box, Button, Stack, Table, TableBody, TableCell, TableHead, TableRow, Typography, styled } from '@mui/material';

// third-party
import { LabelKeyObject } from 'react-csv/components/CommonPropTypes';

import {
  useColumnOrder,
  useExpanded,
  useFilters,
  useGroupBy,
  useGlobalFilter,
  usePagination,
  useRowSelect,
  useSortBy,
  useTable,
  Column,
  HeaderGroup,
  Row,
  Cell,
  CellProps
} from 'react-table';
import { useNavigate } from 'react-router';
// project import
import MainCard from 'components/MainCard';
import ScrollX from 'components/ScrollX';

import {
  HidingSelect,
  HeaderSort,
  TablePagination,
  EmptyTable,
  ExportTableData,
  IndeterminateCheckbox,
  IndeterminateRadio
} from 'components/third-party/ReactTable';

import {
  renderFilterTypes,
  GlobalFilter,
  DefaultColumnFilter,
  SelectColumnFilter,
  SliderColumnFilter,
  NumberRangeColumnFilter,
  customFilter
} from 'utils/react-table';

// assets
import { getSelectedRowType, subUrlType } from 'pages/utils/getSavingsData';
import { useSticky } from 'react-table-sticky';
import { formatValue, lockedDataFormatValue } from 'utils/formatter';
import { DownOutlined, LockFilled, RightOutlined } from '@ant-design/icons';
import { ITableProps } from './TableProps';
import { useSubscriptionPlan } from 'hooks';
import { SubRowAsync } from './SubDataTable';
import { UploadDoc } from '../../pages/infora/cases/UploadDoc';
import { Link } from 'react-router-dom';
// ==============================|| REACT TABLE ||============================== //

const TableWrapper = styled('div')(({ theme: any }) => ({
  '.header': {
    position: 'sticky',
    zIndex: 1,
    width: 'fit-content'
  },
  '& th[data-sticky-td]': {
    position: 'sticky',
    zIndex: '5 !important'
  }
}));

function ReactTable(props: any) {
  const { UserSubscriptionData } = useSubscriptionPlan();
  const { columns, data }: { columns: Column[]; data: [] } = props;
  const { enableRowClick = false, selectedRows, rowKey, handleSelectedRows } = props.rowProperties || {};
  const { savingsType, savingsTypeLabel, showRecommendedAction = false, uploadFileComponent: UploadFileComponent } = props.headerData || {};
  const { searchPlaceholder, handleSearchTable } = props.globalSearch || {};
  const { manualPagination, handlePage } = props.pagination || {};
  const { onActionClick, showFilter, showLockRecords, downloadTableData = true } = props.tableActions || {};
  const theme = useTheme();
  const filterTypes = useMemo(() => renderFilterTypes, []);
  const navigate = useNavigate();
  const defaultColumn: any = useMemo(
    () => ({
      Filter: DefaultColumnFilter
    }),
    []
  );
  const VisibleColumn = props.hideColumns || [];
  const includesTypeColumns = columns.reduce((c: { id: string; value: string }[], v) => {
    if (v.filter === 'includes' && typeof v.accessor === 'string') {
      c.push({ id: v.accessor, value: '' });
    }
    return c;
  }, []);
  const [tableHiddenColumns, setTableHiddenColumns] = useState(props.hideColumns);
  const [lockedData, setLockedData] = useState<Row<{}>[]>([]);

  const initialState = useMemo(
    () => ({
      filters: [...includesTypeColumns],
      hiddenColumns: columns
        .filter((col: Column<{}>) => VisibleColumn.includes(col.accessor as string))
        .map((col) => col.accessor) as string[],
      pageIndex: 0,
      pageSize: 10
    }),
    [savingsType, props.hideColumns]
  );

  const onHiddenColsChange = (val: any) => {
    setTableHiddenColumns(val);
    setHiddenColumns(val);
  };
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    page,
    prepareRow,
    visibleColumns,
    gotoPage,
    state,
    setPageSize,
    setHiddenColumns,
    allColumns,
    state: { hiddenColumns, pageIndex, pageSize },
    preGlobalFilteredRows,
    setGlobalFilter
  } = useTable(
    {
      columns,
      data,
      defaultColumn,
      globalFilter: customFilter,
      initialState,
      filterTypes,
      manualPagination: manualPagination
    },
    useGlobalFilter,
    useFilters,
    useColumnOrder,
    useGroupBy,
    useSortBy,
    useExpanded,
    usePagination,
    useRowSelect,
    useSticky,
    (hooks) => {
      if (props.selection) {
        if (props.selection === 'single')
          hooks.allColumns.push((columns: Column[]) => [
            {
              id: 'row-selection-rd',
              accessor: 'Selection',
              disableSortBy: true,
              Header: '',
              // The cell can use the individual row's getToggleRowSelectedProps method
              // to the render a checkbox
              Cell: ({ row, toggleAllRowsSelected, toggleRowSelected }: any) => {
                // const currentState = row.getToggleRowSelectedProps();
                if (selectedRows && selectedRows[rowKey] === row.original[rowKey]) {
                  row.isSelected = true;
                } else {
                  row.isSelected = false;
                }
                return (
                  <IndeterminateRadio
                    indeterminate={false}
                    title=""
                    onClick={() => {
                      if (handleSelectedRows) {
                        handleSelectedRows(row.original);
                      }
                    }}
                    {...row.getToggleRowSelectedProps()}
                  />
                );
              }
            },
            ...columns
          ]);
        else if (props.selection === 'multi')
          hooks.allColumns.push((columns: Column[]) => [
            {
              id: 'row-selection-chk',
              accessor: 'Selection',
              disableSortBy: true,
              Header: ({ getToggleAllPageRowsSelectedProps }) => (
                <IndeterminateCheckbox indeterminate {...getToggleAllPageRowsSelectedProps()} />
              ),
              Cell: ({ row }: any) => {
                if (selectedRows && selectedRows.includes(row.original[rowKey])) row.isSelected = true;
                return (
                  <IndeterminateCheckbox
                    onClick={() => {
                      if (handleSelectedRows) {
                        handleSelectedRows(row.original[rowKey]);
                      }
                    }}
                    {...row.getToggleRowSelectedProps()}
                  />
                );
              }
            },
            ...columns
          ]);
        else {
          hooks.allColumns.push((columns) => [...columns]);
        }
      } else {
        hooks.allColumns.push((columns) => [...columns]);
      }
    }
  );

  useEffect(() => {
    const row = rows.length > 0 ? Array.from({ length: 5 }, () => rows[0]) : [];
    setLockedData(row);
    setTableHiddenColumns(
      columns.filter((col: Column<{}>) => (props.hideColumns || []).includes(col.accessor as string)).map((col) => col.accessor) as string[]
    );
  }, [savingsType, props.hideColumns, allColumns]);

  const handleRowClick = (row: any) => {
    row = row.original;
    if (enableRowClick) {
      let subType = row.savings_type.replace(/\s+/g, '-');
      subType = getSelectedRowType(subType) || subType;
      navigate(`/trekora/${savingsType}${subUrlType(subType)}/${subType}`);
    }
  };
  let headers: LabelKeyObject[] = [];
  allColumns.map((item) => {
    if (!hiddenColumns?.includes(item.id) && item.id !== 'selection' && item.id !== 'edit') {
      headers.push({ label: typeof item.Header === 'string' ? item.Header : '#', key: item.id });
    }
    return item;
  });
  const handleAction = () => {
    onActionClick();
  };
  const getFileName = () => {
    if (enableRowClick) return `${savingsTypeLabel || savingsType}`;
    else return `${savingsTypeLabel || savingsType} Recommendations`;
  };
  return (
    <>
      <Stack spacing={2}>
        <Stack
          direction={{ xs: 'column', md: 'row' }}
          spacing={{ xs: 1, md: 0 }}
          justifyContent="space-between"
          sx={{ p: 2, pb: 0 }}
          alignItems="center"
        >
          {!enableRowClick ? (
            <GlobalFilter
              preGlobalFilteredRows={preGlobalFilteredRows}
              globalFilter={state.globalFilter}
              setGlobalFilter={setGlobalFilter}
              size="small"
              searchPlaceholder={searchPlaceholder}
              handleSearchTable={handleSearchTable}
            />
          ) : (
            <Stack direction="row" spacing={0.5}>
              <Typography variant="subtitle1" sx={{ color: theme.palette.secondary.main }}>
                Savings Summary
              </Typography>
              <Typography variant="subtitle1" sx={{ color: theme.palette.secondary.main, fontSize: '10px', mt: '5px !important' }}>
                (Past Month)
              </Typography>
            </Stack>
          )}
          <Stack direction={{ xs: 'column', md: 'row' }} spacing={{ xs: 1, md: 2 }}>
            {showRecommendedAction && (
              <Button
                onClick={handleAction}
                variant="contained"
                disabled={!(page.length > 0) || !UserSubscriptionData?.SHOW_RECOMMENDED_ACTION}
              >
                Recommended Action
              </Button>
            )}
            {UploadFileComponent && UploadFileComponent}
            {VisibleColumn.length > 0 && (
              <HidingSelect
                hiddenColumns={tableHiddenColumns!}
                setHiddenColumns={onHiddenColsChange}
                allColumns={allColumns}
                disable={!(page.length > 0)}
              />
            )}
            {downloadTableData && (
              <ExportTableData
                data={data}
                columns={columns}
                filename={getFileName()}
                disable={!(page.length > 0) || !UserSubscriptionData?.ENABLE_TABLE_EXPORT}
              />
            )}
          </Stack>
        </Stack>
        <TableWrapper>
          <Table {...getTableProps()}>
            <TableHead sx={{ borderTopWidth: 2, backgroundColor: 'secondary.main' }}>
              {headerGroups.map((headerGroup) => (
                <TableRow {...headerGroup.getHeaderGroupProps()}>
                  {headerGroup.headers.map((column: HeaderGroup<{}>, index: number) => {
                    return (
                      <TableCell
                        {...column.getHeaderProps([{ className: column.className }])}
                        sx={{
                          color: 'white',
                          position: 'sticky !important',
                          borderTopWidth: 2,
                          backgroundColor: 'secondary.main'
                        }}
                      >
                        <Stack direction="row" spacing={1.15} alignItems="center" sx={{ display: 'inline-flex' }}>
                          <HeaderSort column={column} sort />
                        </Stack>
                      </TableCell>
                    );
                  })}
                </TableRow>
              ))}
            </TableHead>

            {/* striped table -> add class 'striped' */}
            <TableBody {...getTableBodyProps()} className="striped">
              {showFilter &&
                headerGroups.map((group: HeaderGroup<{}>) => (
                  <TableRow {...group.getHeaderGroupProps()}>
                    {group.headers.map((column: HeaderGroup) => (
                      <TableCell {...column.getHeaderProps([{ className: column.className }])}>
                        {column.canFilter ? column.render('Filter') : null}
                      </TableCell>
                    ))}
                  </TableRow>
                ))}
              {page.length > 0 ? (
                page.map((row: Row, i: number) => {
                  prepareRow(row);
                  const rowProps = row.getRowProps();
                  return (
                    <Fragment key={i}>
                      <TableRow
                        {...row.getRowProps()}
                        onClick={() => {
                          handleRowClick(row);
                        }}
                        sx={{ cursor: 'pointer', bgcolor: row.isSelected ? alpha(theme.palette.primary.lighter, 0.35) : 'inherit' }}
                      >
                        {row.cells.map((cell: Cell) => {
                          return (
                            <TableCell
                              {...cell.getCellProps([{ className: cell.column.className }])}
                              style={{ borderRight: '1px solid rgb(240, 240, 240)' }}
                            >
                              {cell.column.format ? formatValue(cell.column.format, cell.value) : cell.render('Cell')}
                            </TableCell>
                          );
                        })}
                      </TableRow>
                      {row.isExpanded && props.renderRowSubComponent({ row, rowProps, visibleColumns })}
                    </Fragment>
                  );
                })
              ) : (
                <EmptyTable msg="No Data" colSpan={9} />
              )}
            </TableBody>
            {showLockRecords && lockedData.length > 0 && (
              <TableBody
                sx={{
                  position: 'relative',
                  backgroundColor: '#E3EFFF',
                  '& .MuiTableRow-root:hover': {
                    backgroundColor: 'transparent !important'
                  }
                }}
              >
                {lockedData.map((row: Row, i: number) => {
                  prepareRow(row);
                  return (
                    <TableRow {...row.getRowProps()} sx={{ filter: 'blur(3px)' }}>
                      {row.cells.map((cell: Cell) => {
                        return (
                          <TableCell
                            {...cell.getCellProps([{ className: `${cell.column.className} filter` }])}
                            style={{ borderRight: '1px solid rgb(240, 240, 240)' }}
                          >
                            {lockedDataFormatValue(cell.column.format, cell.value)}
                          </TableCell>
                        );
                      })}
                    </TableRow>
                  );
                })}
                <TableRow
                  style={{
                    position: 'absolute',
                    top: '0',
                    width: '100%',
                    height: '100%',
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center'
                  }}
                >
                  <TableCell colSpan={9}>
                    <Stack spacing={1} justifyContent="center" alignItems="center">
                      <LockFilled style={{ fontSize: '30px', color: '#00248C' }} />
                      <Typography sx={{ color: '#00248C', fontSize: '16px', fontWeight: 500 }}>
                        Discover All Trekora Has to Offer
                      </Typography>
                      <Button variant="contained" onClick={() => navigate('/limited-access')}>
                        Upgrade Now
                      </Button>
                    </Stack>
                  </TableCell>
                </TableRow>
              </TableBody>
            )}
          </Table>
        </TableWrapper>

        {(props.totalRows || rows.length) > 10 && (
          <Box sx={{ p: 2, py: 0, paddingBottom: 2 }}>
            <TablePagination
              gotoPage={(v) => {
                if (manualPagination) {
                  initialState.pageIndex = v;
                  handlePage({ pageNumber: v, pageSize: initialState.pageSize });
                } else gotoPage(v);
              }}
              rows={props.totalRows || rows.length}
              setPageSize={(v) => {
                if (manualPagination) {
                  initialState.pageIndex = 0;
                  initialState.pageSize = v;
                  handlePage({ pageNumber: 0, pageSize: v });
                  setPageSize(v);
                } else setPageSize(v);
              }}
              pageIndex={pageIndex}
              pageSize={pageSize}
            />
          </Box>
        )}
      </Stack>
    </>
  );
}

const DataTable = (props: ITableProps) => {
  const theme = useTheme();
  const { columns: originalColumns, data, ...restProps } = props;
  const columns = originalColumns.map((c: any) => {
    const column: any = {
      Header: c.label,
      accessor: c.id,
      isInfo: c.isInfo ? true : false,
      className: '',
      filterMethod: null,
      disableFilters: true,
      disableGroupBy: true,
      disableSortBy: c.disableSortBy || false,
      format: c.format
    };
    if (c.filter === 'fuzzyText') {
      column.disableFilters = false;
      column.dataType = 'text';
      column.filter = 'fuzzyText';
    }
    if (c.filter === 'equals') {
      column.filter = 'equals';
      column.Filter = SliderColumnFilter;
      column.disableFilters = false;
    }
    if (c.filter === 'between') {
      column.filter = 'between';
      column.Filter = NumberRangeColumnFilter;
      column.disableFilters = false;
    }
    if (c.filter === 'includes') {
      column.filter = 'includes';
      column.Filter = SelectColumnFilter;
      column.dataType = 'select';
      column.disableFilters = false;
    }
    // if (c.isSort === false) column.disableSortBy = true;
    return column;
  });
  if (props.rowProperties?.subRow)
    columns.splice(0, 0, {
      Header: () => null,
      id: 'expander',
      className: 'cell-center',
      disableSortBy: true,
      width: 70,
      maxWidth: 70,
      minWidth: 70,
      Cell: ({ row }: CellProps<{}>) => {
        const collapseIcon = row.isExpanded ? <DownOutlined /> : <RightOutlined />;
        return (
          <Box sx={{ fontSize: '0.75rem', color: 'text.secondary' }} {...row.getToggleRowExpandedProps()}>
            {collapseIcon}
          </Box>
        );
      },
      SubCell: () => null
    });

  data?.forEach((d: any) => {
    columns.forEach((column: any) => {
      if (column.format) {
        d[column.accessor + '_formatted'] = formatValue(column.format, d[column.accessor]);
      }
    });
  });

  // const renderRowSubComponent = useCallback(() => <SubRowAsync />, []);
  const renderRowSubComponent = useCallback(
    ({ row, rowProps }: any) => {
      return row.original['numberOfDocuments'] === 0 ? (
        <UploadDoc options={data} row={row} onFileUpload={props.subTable?.onFileUpload} rowProps={rowProps} />
      ) : (
        <SubRowAsync row={row} rowProps={rowProps} columns={props.subTable?.columns} data={getSubTableData(row)} />
      );
    },
    [props.subTable?.data]
  );

  const getSubTableData = (row: any) => {
    const selectedRow = props.subTable?.data.filter((d: any) => d.caseId === row.original['caseId']);
    return selectedRow.map((doc: any) => {
      return {
        ...doc.caseDocuments[0],
        docName:
          doc.caseStatus === 'success' ? (
            <Link to={`/Infora/document/${doc?.caseId}/${doc?.caseDocuments[0]?.docId}`} style={{ color: theme.palette.primary.main }}>
              {doc.caseDocuments[0]?.name}
            </Link>
          ) : (
            <Typography sx={{ color: theme.palette.primary.main }}>{doc.caseDocuments[0]?.name}</Typography>
          )
      };
    });
  };

  return (
    <MainCard
      title={props.headerData?.title || ''}
      secondary={props.headerData?.secondary}
      subheader={props.headerData?.subheader || ''}
      content={false}
    >
      <ScrollX>
        <ReactTable columns={columns} data={data} {...restProps} renderRowSubComponent={renderRowSubComponent} />
      </ScrollX>
    </MainCard>
  );
};

export default DataTable;
