import { lazy, useEffect } from 'react';

// project import
import MainLayout from 'layout/MainLayout';
import Loadable from 'components/Loadable';
import AuthGuard from 'utils/route-guard/AuthGuard';
import { SessionManager } from 'SessionManager';
import { Navigate, useLocation, useParams } from 'react-router';
import { ProfileComponent } from 'layout/MainLayout/Header/HeaderContent/Profile/ProfileComponent';
import { useAuth, useEnvironment, useSelectedProduct, useSubscriptionPlan, useUserData } from 'hooks';
import { ArchitectureImages } from 'pages/sap/architectureImages';
import { SpecificSavingType as ArchitecturalSubSaving } from 'pages/architecturalSavings/specificSavingsType';
import { SpecificSavingType as CommercialSubSaving } from 'pages/commercialSavings/specificSavingsType';
import { isArchitecturalItems } from 'pages/utils/getSavingsData';
import EnvironmentMode from 'enums/environmentMode';
import Products from 'enums/products';
import Roles from 'enums/roles';
import { StringArrayMap } from 'constants/types';

const AwsSetUp: React.FC = Loadable(lazy(() => import('pages/aws-setup')));
const Step3 = Loadable(lazy(() => import('pages/aws-setup/step3')));
const DashboardAnalytics = Loadable(lazy(() => import('pages/dashboard/analytics')));
const CommercialSavings = Loadable(lazy(() => import('pages/commercialSavings')));
const OperationalSavings = Loadable(lazy(() => import('pages/operationalSavings')));
const ArchitecturalSavings = Loadable(lazy(() => import('pages/architecturalSavings')));
const Home = Loadable(lazy(() => import('pages/home')));
const Faqs = Loadable(lazy(() => import('pages/faq')));
const Questionnaire = Loadable(lazy(() => import('pages/sap/questionnarie')));
const SAPAssist = Loadable(lazy(() => import('pages/sap')));
const SapInfo = Loadable(lazy(() => import('pages/sap/SapInfo')));
const ServerError = Loadable(lazy(() => import('pages/maintenance/Error')));
const PageNotFound = Loadable(lazy(() => import('pages/maintenance/404')));
const ChangePassword = Loadable(lazy(() => import('layout/MainLayout/Header/HeaderContent/Profile/ChangePassword')));
const Account = Loadable(lazy(() => import('pages/account/Account')));
const Role = Loadable(lazy(() => import('pages/account/Role')));
// const Organization = Loadable(lazy(() => import('pages/account/Organization')));
const CompletedPlan = Loadable(lazy(() => import('pages/sap/CompletedPlan')));
const ViewLatestRecord = Loadable(lazy(() => import('pages/sap/ViewLatestRecord')));
const Traverse = Loadable(lazy(() => import('pages/traverse')));
const Welcome = Loadable(lazy(() => import('pages/traverse/welcome')));
const Resources = Loadable(lazy(() => import('pages/traverse/explore/resources')));
const Costs = Loadable(lazy(() => import('pages/traverse/explore/costs')));
const Views = Loadable(lazy(() => import('pages/traverse/explore/views')));
const Accounts = Loadable(lazy(() => import('pages/traverse/configure/accounts')));
const Manage = Loadable(lazy(() => import('pages/traverse/diagrams/manage')));
const Open = Loadable(lazy(() => import('pages/traverse/diagrams/manage/open')));
const CostOverview = Loadable(lazy(() => import('pages/traverse/explore/costs/Report/CostOverview')));
const ExportDiagram = Loadable(lazy(() => import('pages/traverse/Diagram/Draw/Canvas/Export/ExportDiagram')));
const CreateDiagram = Loadable(lazy(() => import('pages/traverse/diagrams/manage/create')));
const CreateView = Loadable(lazy(() => import('pages/traverse/explore/views/create')));
const ImportAccounts = Loadable(lazy(() => import('pages/traverse/configure/accounts/import')));
const LimitedAccess = Loadable(lazy(() => import('pages/limitedaccess')));
const SapDashbord = Loadable(lazy(() => import('pages/sap/dashboard')));
const ManagedServices = Loadable(lazy(() => import('pages/limitedaccess/managedServices')));
const Decrypt = Loadable(lazy(() => import('pages/Decrypt')));
const Cases = Loadable(lazy(() => import('pages/infora/cases')));
const Document = Loadable(lazy(() => import('pages/infora/document')));
const Infora = Loadable(lazy(() => import('pages/infora')));
const Chat = Loadable(lazy(() => import('pages/chat')));
const LoggingIn = Loadable(lazy(() => import('pages/middleware/loggingIn')));
const Solutions = Loadable(lazy(() => import('pages/solutions')));
const VideoSummarize = Loadable(lazy(() => import('pages/video_summarize')));
const HotTopics = Loadable(lazy(() => import('pages/hot_topics')));

const validCommercialTypes: StringArrayMap = {
  RI: ['AMAZON-ELASTICACHE', 'AMAZON-OPENSEARCH', 'AMAZON-REDSHIFT', 'AMAZON-RDS'],
  'SAVINGS-PLAN': ['COMPUTE', 'EC2', 'SAGEMAKER']
};

const validArchitecturalTypes: StringArrayMap = {
  'AMD-MIGRATION': [],
  'DATA-TRANSFER': ['OUT-TO-INTERNET', 'INTER-AZ', 'INTER-REGION'],
  'EBS-MODERNIZATION': [],
  'NAT-GATEWAY': ['HOURS', 'BYTES'],
  'S3-OPTIMIZATION': ['API', 'STORAGE'],
  'GRAVITON-MIGRATION': []
};
const validOperationalTypes: string[] = ['UNDERUTILIZED-EC2', 'UNDERUTILIZED-EBS', 'UNDERUTILIZED-ELB'];

// ==============================|| MAIN ROUTING ||============================== //
function SetupInComplete({ children }: { children: JSX.Element }) {
  const { getUserData } = useUserData();
  const isNewAccount = getUserData('isNewAccount');
  const selectedType = getUserData('selectedType');
  const { UserSubscriptionData } = useSubscriptionPlan();
  const { isTrekora } = useSelectedProduct();
  const addedAccountsCount = getUserData('awsDetailsLength');

  if (selectedType === '') {
    return <Navigate to="/home" replace />;
  } else if (addedAccountsCount === UserSubscriptionData?.NO_OF_ACCOUNTS) {
    if (isTrekora) return <Navigate to="/trekora/savingsReview" replace />;
    else return <Navigate to="/home" replace />;
  } else if (!isNewAccount) {
    return <Navigate to="/trekora/savingsReview" replace />;
  }
  return <>{children}</>;
}

function SetupComplete({ children }: { children: JSX.Element }) {
  const { getUserData } = useUserData();
  const selectedAccountId = getUserData('selectedAwsAccountId');
  const { isTrekora } = useSelectedProduct();

  if (!isTrekora) {
    return <Navigate to="/home" replace />;
  } else if (isTrekora && selectedAccountId === '') {
    return <Navigate to="/trekora/savingsReview" replace />;
  }
  return <>{children}</>;
}

function GetAdminPaths({ children }: { children: JSX.Element }) {
  const { getUserData } = useUserData();
  const role = getUserData('userData')?.role;
  if (role === Roles.ADMIN) {
    return <>{children}</>;
  } else return <PageNotFound />;
}

function ResetSelection({ children }: { children: JSX.Element }) {
  const { addUserData } = useUserData();

  const location = useLocation();

  useEffect(() => {
    if (location.pathname !== '/account/organization') {
      addUserData('selectedType', '');
      addUserData('selectedAwsAccountId', '');
    }
  }, [location.pathname]);
  return <>{children}</>;
}

function SetProduct({ type, children }: { type: string; children: JSX.Element }) {
  const { addUserData } = useUserData();
  addUserData('selectedType', type!);
  return <>{children}</>;
}

function CheckSelection({ type, children }: { type: string; children: JSX.Element }) {
  const { getUserData } = useUserData();
  const { UserSubscriptionData } = useSubscriptionPlan();
  const location = useLocation();
  const { isLoggedIn } = useAuth();
  const traverseToken = window.localStorage.getItem('traverseToken');
  const inforaToken = window.localStorage.getItem('inforaToken');

  //Remove this condition when traverse and infora are enabled
  if (['traverse'].includes(type.toLowerCase())) {
    return <Navigate to="/home" replace />;
  }

  if (location.pathname.toLowerCase().includes(Products.TRAVERSE.toLowerCase()) && !traverseToken && isLoggedIn) {
    return <LoggingIn routeUrl={location.pathname} product={Products.TRAVERSE} />;
  }
  if (location.pathname.toLowerCase().includes(Products.INFORA.toLowerCase()) && !inforaToken && isLoggedIn) {
    return <LoggingIn routeUrl={location.pathname} product={Products.INFORA} />;
  }
  if (getUserData('selectedType').toLowerCase() !== type.toLowerCase()) {
    return <Navigate to="/home" replace />;
  } else if (!UserSubscriptionData?.PRODUCT_ACCESS[type.toUpperCase() as keyof typeof Products]) {
    return <Navigate to="/home" replace />;
  }
  return <>{children}</>;
}

function CommercialSavingSubType() {
  const { addUserData } = useUserData();
  const { ctype, type } = useParams();
  if (
    Object.keys(validCommercialTypes).includes(ctype!.toUpperCase()) &&
    validCommercialTypes[ctype!.toUpperCase()].includes(type!.toUpperCase())
  )
    return <CommercialSubSaving key={type} />;
  else {
    addUserData('selectedAwsAccountId', '');
    return <PageNotFound />;
  }
}

function ArchitecturalSavingSubType() {
  const { addUserData } = useUserData();
  const { atype, type } = useParams();
  if (
    atype !== undefined &&
    type !== undefined &&
    Object.keys(validArchitecturalTypes).includes(atype!.toUpperCase()) &&
    validArchitecturalTypes[atype!.toUpperCase()].includes(type!.toUpperCase())
  )
    return <ArchitecturalSubSaving key={atype + '' + type} />;
  else if (atype !== undefined && type === undefined && Object.keys(validArchitecturalTypes).includes(atype!.toUpperCase())) {
    if (isArchitecturalItems(atype!)) {
      return <ArchitecturalSubSaving key={atype} />;
    } else return <ArchitecturalSavings key={atype} />;
  } else {
    addUserData('selectedAwsAccountId', '');
    return <PageNotFound />;
  }
}

function OperationalSavingSubType() {
  const { addUserData } = useUserData();
  const { type } = useParams();
  if (validOperationalTypes.includes(type!.toUpperCase())) return <OperationalSavings key={type} />;
  else {
    addUserData('selectedAwsAccountId', '');
    return <PageNotFound />;
  }
}

function SavingsType() {
  const location = useLocation();
  const { addUserData } = useUserData();
  const params = useParams();
  if (location.pathname.split('/')[2].toUpperCase() === 'ARCHITECTURALSAVINGS') {
    if (Object.keys(validArchitecturalTypes).includes(params.atype!.toUpperCase())) return <ArchitecturalSavings key={params.atype!} />;
  } else if (location.pathname.split('/')[2].toUpperCase() === 'OPERATIONALSAVINGS') {
    if (validOperationalTypes.includes(params.type!.toUpperCase())) return <OperationalSavings key={params.type!} />;
  } else if (location.pathname.split('/')[2].toUpperCase() === 'COMMERCIALSAVINGS') {
    if (Object.keys(validCommercialTypes).includes(params.ctype!.toUpperCase())) return <CommercialSavings key={params.ctype!} />;
  }
  addUserData('selectedAwsAccountId', '');
  return <PageNotFound />;
}

function HandleOrganization({ children }: { children: JSX.Element }) {
  const { addUserData } = useUserData();
  const { isTrekora } = useSelectedProduct();
  addUserData('selectedAwsAccountId', '');
  if (!isTrekora) {
    return <Navigate to="/home" replace />;
  }
  return <>{children}</>;
}

function CheckEnvType({ children }: { children: JSX.Element }) {
  const { currentEnvironment } = useEnvironment();
  if (currentEnvironment !== EnvironmentMode.DEVELOPMENT) {
    return <PageNotFound />;
  }
  return <>{children}</>;
}

const MainRoutes = {
  path: '/',
  children: [
    {
      path: '/',
      element: (
        <AuthGuard>
          <SessionManager>
            <MainLayout />
          </SessionManager>
        </AuthGuard>
      ),
      children: [
        {
          path: 'decrypt',
          element: (
            <ResetSelection>
              <CheckEnvType>
                <Decrypt />
              </CheckEnvType>
            </ResetSelection>
          )
        },
        {
          path: 'limited-access',
          element: (
            <ResetSelection>
              <LimitedAccess />
            </ResetSelection>
          )
        },
        {
          path: 'managed-services',
          element: (
            <ResetSelection>
              <ManagedServices />
            </ResetSelection>
          )
        },
        {
          path: 'needHelp',
          element: (
            <ResetSelection>
              <AwsSetUp />
            </ResetSelection>
          )
        },
        {
          path: 'faq',
          element: (
            <ResetSelection>
              <Faqs />
            </ResetSelection>
          )
        },
        {
          path: 'home',
          element: (
            <ResetSelection>
              <Home />
            </ResetSelection>
          )
        },
        {
          path: 'solutions',
          element: (
            <SetProduct type={Products.SOLUTIONS}>
              <Solutions />
            </SetProduct>
          )
        },
        {
          path: 'video_summarize',
          element: (
            <SetProduct type={Products.VIDEO_SUMMARIZE}>
              <VideoSummarize />
            </SetProduct>
          )
        },
        {
          path: 'hot_topics',
          element: (
            <SetProduct type={Products.HOT_TOPICS}>
              <HotTopics />
            </SetProduct>
          )
        },
        {
          path: 'account',
          element: (
            <ResetSelection>
              <Account />
            </ResetSelection>
          ),
          children: [
            {
              path: 'profile',
              element: <ProfileComponent />
            },
            {
              path: 'organization',
              element: (
                <HandleOrganization>
                  <Step3 />
                </HandleOrganization>
              )
            },
            {
              path: 'role',
              element: (
                <GetAdminPaths>
                  <Role />
                </GetAdminPaths>
              )
            },
            {
              path: 'change-password',
              element: <ChangePassword />
            }
          ]
        },
        {
          path: Products.TRAVERSE.toLowerCase(),
          element: (
            <CheckSelection type={Products.TRAVERSE}>
              <Traverse />
            </CheckSelection>
          ),
          children: [
            {
              path: 'welcome',
              element: <Welcome />
            },
            {
              path: 'resources',
              element: <Resources />
            },
            {
              path: 'views',
              element: <Views />
            },
            {
              path: 'views/create',
              element: <CreateView />
            },
            {
              path: 'views/:name',
              element: <Views />
            },
            {
              path: 'views/:name/edit',
              element: <CreateView />
            },
            {
              path: 'costs',
              element: <Costs />
            },
            {
              path: 'accounts',
              element: <Accounts />
            },
            {
              path: 'accounts/import',
              element: <ImportAccounts />
            },
            {
              path: 'diagrams',
              element: <Manage />
            },
            {
              path: 'diagrams/create',
              element: <CreateDiagram />
            },
            {
              path: 'diagrams/:visibility/:name',
              element: <Open />
            },
            {
              path: 'diagrams/:visibility/:name/cost_report',
              element: <CostOverview />
            },
            {
              path: 'diagrams/:visibility/:name/export',
              element: <ExportDiagram />
            }
          ]
        },
        {
          path: Products.TREKORA.toLowerCase(),
          children: [
            {
              path: 'awsAccountSetup',
              element: (
                <SetupInComplete>
                  <AwsSetUp />
                </SetupInComplete>
              )
            },
            {
              path: 'dashboard',
              element: (
                <SetupComplete>
                  <DashboardAnalytics />
                </SetupComplete>
              )
            },
            {
              path: 'commercialSavings',
              element: (
                <SetupComplete>
                  <CommercialSavings />
                </SetupComplete>
              )
            },
            {
              path: 'commercialSavings/:ctype',
              element: (
                <SetupComplete>
                  <SavingsType />
                </SetupComplete>
              )
            },
            {
              path: 'commercialSavings/:ctype/:type',
              element: (
                <SetupComplete>
                  <CommercialSavingSubType />
                </SetupComplete>
              )
            },
            {
              path: 'operationalSavings',
              element: (
                <SetupComplete>
                  <OperationalSavings />
                </SetupComplete>
              )
            },
            {
              path: 'operationalSavings/:type',
              element: (
                <SetupComplete>
                  <OperationalSavingSubType />
                </SetupComplete>
              )
            },
            {
              path: 'architecturalSavings',
              element: (
                <SetupComplete>
                  <ArchitecturalSavings />
                </SetupComplete>
              )
            },
            {
              path: 'architecturalSavings/:atype',
              element: (
                <SetupComplete>
                  <ArchitecturalSavingSubType />
                </SetupComplete>
              )
            },
            {
              path: 'architecturalSavings/:atype/:type',
              element: (
                <SetupComplete>
                  <ArchitecturalSavingSubType />
                </SetupComplete>
              )
            },
            {
              path: 'savingsReview',
              element: (
                <CheckSelection type={Products.TREKORA}>
                  <Step3 />
                </CheckSelection>
              )
            }
          ]
        },
        {
          path: Products.SAPASSIST.toLowerCase(),
          element: (
            <CheckSelection type={Products.SAPASSIST}>
              <SAPAssist />
            </CheckSelection>
          ),
          children: [
            {
              path: 'dashboard',
              element: <SapDashbord />
            },
            {
              path: 'completed-migration/:id',
              element: <CompletedPlan />
            },
            {
              path: 'questionarrie',
              element: <Questionnaire />
            },
            {
              path: 'info/:id',
              element: <SapInfo />
            },
            {
              path: 'targetArchitectures/:id',
              element: <ArchitectureImages />
            },
            {
              path: 'completed-migration/:id/preview',
              element: <ViewLatestRecord />
            }
          ]
        },
        {
          path: 'Infora',
          element: (
            <CheckSelection type={Products.INFORA}>
              <Infora />
            </CheckSelection>
          ),
          children: [
            {
              path: 'cases',
              element: <Cases />
            },
            {
              path: 'document/:case_id/:doc_id',
              element: <Document />
            }
          ]
        },
        {
          path: 'Chat',
          element: (
            <ResetSelection>
              <Chat />
            </ResetSelection>
          )
        },
        {
          path: 'Error',
          children: [
            {
              path: 'Server',
              element: (
                <ResetSelection>
                  <ServerError />
                </ResetSelection>
              )
            },
            {
              path: 'PageNotFound',
              element: (
                <ResetSelection>
                  <PageNotFound />
                </ResetSelection>
              )
            }
          ]
        },
        {
          path: '*',
          element: (
            <ResetSelection>
              <PageNotFound />
            </ResetSelection>
          )
        }
      ]
    }
  ]
};

export default MainRoutes;
