import { StaticPathDetails, DynamicPathDetails } from 'constants/types';

const dynamicPaths: DynamicPathDetails[] = [
  {
    pattern: /^\/traverse\/views\/[^/]+$/,
    message: {
      title: 'Traverse Views',
      description: 'Traverse Views'
    }
  },
  {
    pattern: /^\/traverse\/views\/[^/]+\/edit$/,
    message: {
      title: 'Traverse Views-Edit',
      description: 'Traverse Views-Edit'
    }
  },
  {
    pattern: /^\/traverse\/diagrams\/[^/]+\/[^/]+$/,
    message: {
      title: 'Traverse Diagrams',
      description: 'Traverse Diagrams'
    }
  },
  {
    pattern: /^\/traverse\/diagrams\/[^/]+\/[^/]+\/cost_report$/,
    message: {
      title: 'Traverse Diagrams-Cost Report',
      description: 'Traverse Diagrams-Cost Report'
    }
  },
  {
    pattern: /^\/traverse\/diagrams\/[^/]+\/[^/]+\/export$/,
    message: {
      title: 'Traverse Diagrams-Export',
      description: 'Traverse Diagrams-Export'
    }
  },
  {
    pattern: /^\/sapassist\/completed-migration\/[^/]+$/,
    message: {
      title: 'SAP Completed Assessment',
      description: 'SAP Completed Assessment'
    }
  },
  {
    pattern: /^\/sapassist\/info\/[^/]+$/,
    message: {
      title: 'SAP Info',
      description: 'SAP Info'
    }
  },
  {
    pattern: /^\/sapassist\/targetarchitectures\/[^/]+$/,
    message: {
      title: 'SAP Target Architectures',
      description: 'SAP Target Architectures'
    }
  },
  {
    pattern: /^\/sapassist\/completed-migration\/[^/]+\/preview$/,
    message: {
      title: 'SAP Completed Assessment Preview',
      description: 'SAP Completed Assessment Preview'
    }
  },
  {
    pattern: /^\/infora\/cases$/,
    message: {
      title: 'Infora Cases',
      description: 'Infora Cases'
    }
  },
  {
    pattern: /^\/infora\/document\/[^/]+\/[^/]+$/,
    message: {
      title: 'Infora Specific Document',
      description: 'Infora Specific Document'
    }
  }
];

const staticPaths: StaticPathDetails = {
  '/home': {
    title: 'Home',
    description: 'Home'
  },
  '/decrypt': {
    title: 'Decrypt Data',
    description: 'Decrypt Data'
  },
  '/trekora/savingsreview': { title: 'Summary and Review of savings', description: 'Summary and Review of savings' },
  '/trekora/awsaccountsetup': { title: 'AWS Account Setup', description: 'AWS Account Setup' },
  '/trekora/dashboard': { title: 'Dashboard', description: 'Dashboard' },
  '/trekora/commercialsavings': { title: 'Commercial Savings', description: 'Commercial Savings' },
  '/trekora/commercialsavings/ri': {
    title: 'Commercial Savings-Reserved Instances',
    description: 'Commercial Savings-Reserved Instances'
  },
  '/trekora/commercialsavings/ri/amazon-elasticache': {
    title: 'Reserved Instances-Amazon-ElastiCache',
    description: 'Reserved Instances-Amazon-ElastiCache'
  },
  '/trekora/commercialsavings/ri/amazon-opensearch': {
    title: 'Reserved Instances-Amazon-Opensearch',
    description: 'Reserved Instances-Amazon-Opensearch'
  },
  '/trekora/commercialsavings/ri/amazon-redshift': {
    title: 'Reserved Instances-Amazon-Redshift',
    description: 'Reserved Instances-Amazon-Redshift'
  },
  '/trekora/commercialsavings/ri/amazon-rds': {
    title: 'Reserved Instances-Amazon-Relational-Database',
    description: 'Reserved Instances-Amazon-Relational-Database'
  },
  '/trekora/commercialsavings/savings-plan': {
    title: 'Commercial Savings-Savings Plan',
    description: 'Commercial Savings-Savings Plan'
  },
  '/trekora/commercialsavings/savings-plan/compute': {
    title: 'Savings Plan-Compute',
    description: 'Savings Plan-Compute'
  },
  '/trekora/commercialsavings/savings-plan/ec2': {
    title: 'Savings Plan-EC2',
    description: 'Savings Plan-EC2'
  },
  '/trekora/commercialsavings/savings-plan/sagemaker': {
    title: 'Savings Plan-SageMaker',
    description: 'Savings Plan-SageMaker'
  },

  '/trekora/architecturalsavings': { title: 'Architectural Savings', description: 'Architectural Savings' },
  '/trekora/architecturalsavings/amd-migration': {
    title: 'Architectural Savings-AMD Migration',
    description: 'Architectural Savings-AMD Migration'
  },
  '/trekora/architecturalsavings/ebs-modernization': {
    title: 'Architectural Savings-EBS Modernization',
    description: 'Architectural Savings-EBS Modernization'
  },
  '/trekora/architecturalsavings/graviton-migration': {
    title: 'Architectural Savings-Graviton',
    description: 'Architectural Savings-Graviton'
  },
  '/trekora/architecturalsavings/data-transfer': {
    title: 'Architectural Savings-Data Transfer',
    description: 'Architectural Savings-Data Transfer'
  },
  '/trekora/architecturalsavings/data-transfer/out-to-internet': {
    title: 'Data Transfer-DTO',
    description: 'Data Transfer-DTO'
  },
  '/trekora/architecturalsavings/data-transfer/inter-az': {
    title: 'Data Transfer-DTAZ',
    description: 'Data Transfer-DTAZ'
  },
  '/trekora/architecturalsavings/data-transfer/inter-region': {
    title: 'Data Transfer-Inter Region',
    description: 'Data Transfer-Inter Region'
  },
  '/trekora/architecturalsavings/nat-gateway': {
    title: 'Architectural Savings-NAT Gateway',
    description: 'Architectural Savings-NAT Gateway'
  },
  '/trekora/architecturalsavings/nat-gateway/bytes': {
    title: 'NAT Gateway-Bytes',
    description: 'NAT Gateway-Bytes'
  },
  '/trekora/architecturalsavings/nat-gateway/hours': {
    title: 'NAT Gateway-Hours',
    description: 'NAT Gateway-Hours'
  },
  '/trekora/architecturalsavings/s3-optimization': {
    title: 'Architectural Savings-S3 Optimization',
    description: 'Architectural Savings-S3 Optimization'
  },
  '/trekora/architecturalsavings/s3-optimization/api': {
    title: 'S3 Optimization-API',
    description: 'S3 Optimization-API'
  },
  '/trekora/architecturalsavings/s3-optimization/storage': {
    title: 'S3 Optimization-Storage',
    description: 'S3 Optimization-Storage'
  },
  '/trekora/operationalsavings': { title: 'Operational Savings', description: 'Operational Savings' },
  '/trekora/operationalsavings/underutilized-ec2': {
    title: 'Operational Savings - EC2 Instances',
    description: 'Operational Savings - EC2 Instances'
  },
  '/trekora/operationalsavings/underutilized-ebs': {
    title: 'Operational Savings - EBS Volumes',
    description: 'Operational Savings - EBS Volumes'
  },
  '/trekora/operationalsavings/underutilized-elb': {
    title: 'Operational Savings - ELB',
    description: 'Operational Savings - ELB'
  },
  '/needhelp': { title: 'Need Help', description: 'Need Help' },
  '/faq': { title: 'FAQ', description: 'FAQ' },
  '/account/profile': { title: 'Profile', description: 'Profile' },
  '/account/organization': { title: 'Organization', description: 'Organization' },
  '/account/change-password': { title: 'Change Password', description: 'Change Password' },
  '/account/role': { title: 'Role', description: 'Role' },
  '/sapassist/questionnaire': { title: 'SAP on AWS Customer Questionnaire', description: 'SAP on AWS Customer Questionnaire' },
  '/sapassist/dashboard': {
    title: 'SAP Dashboard',
    description: 'SAP Dashboard'
  },
  '/limited-access': {
    title: 'Plan Features',
    description: 'Plan Features'
  },
  '/managed-services': {
    title: 'Managed Services',
    description: 'Managed Services'
  },
  '/traverse/welcome': {
    title: 'Traverse Welcome Page',
    description: 'Traverse Welcome Page'
  },
  '/traverse/resources': {
    title: 'Traverse Resources',
    description: 'Traverse Resources'
  },
  '/traverse/views': {
    title: 'Traverse Views',
    description: 'Traverse Views'
  },
  '/traverse/views/create': {
    title: 'Traverse Views-Create',
    description: 'Traverse Views-Create'
  },
  '/traverse/costs': {
    title: 'Traverse Costs',
    description: 'Traverse Costs'
  },
  '/traverse/accounts': {
    title: 'Traverse Accounts',
    description: 'Traverse Accounts'
  },
  '/traverse/accounts/import': {
    title: 'Traverse Accounts-Import',
    description: 'Traverse Accounts-Import'
  },

  '/traverse/diagrams': {
    title: 'Traverse Diagrams',
    description: 'Traverse Diagrams'
  },
  '/traverse/diagrams/create': {
    title: 'Traverse Diagrams-Create',
    description: 'Traverse diagrams-Create'
  },
  '/chat': {
    title: 'ChatGenie',
    description: 'ChatGenie'
  },
  '/error': {
    title: 'Page Not Found',
    description: 'Page Not Found'
  },
  '/error/pagenotfound': {
    title: 'Page Not Found',
    description: 'Page Not Found'
  },
  '/error/server': {
    title: 'Internal Server Error',
    description: 'Internal Server Error'
  },
  '/register': {
    title: 'Discover Cloud Sign Up',
    description: 'Discover Cloud Sign Up'
  },
  '/code-verification': {
    title: 'Discover Cloud Sign Up',
    description: 'Discover Cloud Sign Up'
  },
  '/': {
    title: 'Discover Cloud Sign In',
    description: 'Discover Cloud Sign In'
  },
  '/login': {
    title: 'Discover Cloud Sign In',
    description: 'Discover Cloud Sign In'
  },
  '/forgot-password': {
    title: 'Discover Cloud Sign In',
    description: 'Discover Cloud Sign In'
  },
  '/reset-password': {
    title: 'Discover Cloud Sign In',
    description: 'Discover Cloud Sign In'
  },
  '/check-mail': {
    title: 'Discover Cloud',
    description: 'Discover Cloud'
  },
  '/solutions': {
    title: 'Solutions',
    description: 'Available Solutions'
  },
  '/video_summarize': {
    title: 'Video Summarization',
    description: 'Video Summarization'
  },
  '/hot_topics': {
    title: 'Hot Topics',
    description: 'Hot Topics'
  }
};

export const getPageDescription = (pathname: string) => {
  pathname = pathname.toLowerCase();
  if (staticPaths[pathname]) {
    return staticPaths[pathname];
  } else {
    for (const item of dynamicPaths) {
      if (item.pattern.test(pathname)) {
        return item.message;
      }
    }
  }
  return {
    title: 'Page Not Found',
    description: 'Page Not Found'
  };
};
